import React from 'react'
import Helmet from 'react-helmet'
import { Layout, Heading, Row, Column, Image, Section, Text } from 'components'
import { Col } from 'react-bootstrap'
import Blog from 'images/blogs/blog-16.png'
import Testi1 from 'images/blogs/testi/testi-1.jpg'
import Testi2 from 'images/blogs/testi/testi-2.jpg'
import Testi3 from 'images/blogs/testi/testi-3.jpg'
import Testi4 from 'images/blogs/testi/testi-4.jpg'
import Testi5 from 'images/blogs/testi/testi-5.jpg'
import Testi6 from 'images/blogs/testi/testi-6.jpg'
import Testi7 from 'images/blogs/testi/testi-7.jpg'
import Testi8 from 'images/blogs/testi/testi-8.jpg'
import Testi9 from 'images/blogs/testi/testi-9.jpg'
import Testi10 from 'images/blogs/testi/testi-10.jpg'
import 'components/css/stories.css'
import whyAgentsLoveDigipayData from '../../data/static/WhyAgentsLoveDigipayData'
import HostName from '../../data/static/HostNames'

const SITE_META = [
  {
    name: 'description',
    content: 'Mabilis, Madali, Mura',
  },
  {
    name: 'keywords',
    content:
      'digipay, fintech, e-payments, bills payment, ' +
      'mobile money, e-load, e-pins, microinsurance',
  },
  {
    property: 'og:url',
    content: `https://${process.env.GATSBY_REACT_APP_HOSTNAME}/stories/WhyAgentsLoveDigipay`,
  },
  {
    property: 'og:type',
    content: 'website',
  },
  {
    property: 'og:title',
    content: 'Digpay Agents Answer Why They Love Digipay',
  },
  {
    property: 'og:description',
    content: 'Ikaw ka-Digipay, anong pinakagusto mo sa negosyong to?',
  },
  {
    property: 'og:image',
    content: Blog,
  },
]

const ReasonsWhy = () => (
  <Layout>
    <Helmet
      title="Digipay Agents Answer Why They Love Digipay"
      meta={SITE_META}
    >
      <html lang="en" />
    </Helmet>

    <Row>
      <Column size="df:[100%] lg:[col-6]" py="sp4" px="sp3" m="auto">
        <Image width="100%" mb="sp3" src={Blog} />
        <Heading
          className="blog__title"
          mb="sp4"
          fontWeight="bold"
          textAlign="center"
        >
          DIGIPAY AGENTS ANSWER WHY THEY LOVE DIGIPAY
        </Heading>

        <Section mb="sp4">
          <Text>
            Noong January 22 ay nagpamigay ng free tickets ang Digipay para sa
            D’Ninang Block Screening, starring our very own Aling Digi, Ms.
            Ai-Ai delas Alas. Deserve ng ating Digipay Agents na mag-relax,
            tumawa at mag-enjoy kasama ang kapwa nila ka-Digipay.
          </Text>
          <Text>
            Para manalo, kailangan lang kumpletuhin ang sentence na ‘to: “I love
            Digipay because…” Pinost ito sa Digipay Agent Group at napakaraming
            sumagot.
          </Text>
          <Text>Here are the top 10 answers:</Text>
        </Section>

        {whyAgentsLoveDigipayData.map((value, index) => {
          return (
            <Section key={index} mb="sp4">
              <Row>
                <Col className="blog__feedback-user">
                  <img
                    className="blog__feedback-user-photo"
                    src={value.feedBackImageSource}
                  />
                </Col>
                <Col className="blog__feedback-content">
                  <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
                    {value.feedbackTitle}
                  </Heading>
                  <Text>
                    {value.feedbackContent} <br />
                    <br /> {value.feedbackAuthor}
                  </Text>
                </Col>
              </Row>
            </Section>
          )
        })}
        <Section mb="sp4">
          <Text>
            Hindi ka mauubusan ng rason para mahalin ang Digipay. Our goal is to
            provide a source of extra income para sa ating mga ka-Digipay with
            our user-friendly app. Gusto mo na bang maging isang Digipay Agent?
            Sign-up{' '}
            <a
              className="inline-link"
              href={`https://${process.env.GATSBY_REACT_APP_HOSTNAME}/sign-up`}
            >
              here
            </a>
            .
          </Text>
          <Text>Hanggang sa muli, ka-Digipay!</Text>
        </Section>
      </Column>
    </Row>
  </Layout>
)

export default ReasonsWhy
