import React from 'react'
import Helmet from 'react-helmet'
import { Layout, Heading, Row, Column, Image, Section, Text } from 'components'
import { Col } from 'react-bootstrap'
import Blog from 'images/blogs/blog-16.png'
import Testi1 from 'images/blogs/testi/testi-1.jpg'
import Testi2 from 'images/blogs/testi/testi-2.jpg'
import Testi3 from 'images/blogs/testi/testi-3.jpg'
import Testi4 from 'images/blogs/testi/testi-4.jpg'
import Testi5 from 'images/blogs/testi/testi-5.jpg'
import Testi6 from 'images/blogs/testi/testi-6.jpg'
import Testi7 from 'images/blogs/testi/testi-7.jpg'
import Testi8 from 'images/blogs/testi/testi-8.jpg'
import Testi9 from 'images/blogs/testi/testi-9.jpg'
import Testi10 from 'images/blogs/testi/testi-10.jpg'
import 'components/css/stories.css'

const whyAgentsLoveDigipayData = [
  {
    feedBackImageSource: Testi1,
    feedbackTitle: 'EXTRA INCOME',
    feedbackContent:
      '“I love digipay dahil nakakatulong ito sakin para magkaroon ng extra income at very convenient para sa mga cash-in transactions ng GCash at PayMaya. Transparent din dahil namomonitor lahat ng transactions at income mo.” ',
    feedbackAuthor: '- Richie Fajanilan',
  },
  {
    feedBackImageSource: Testi2,
    feedbackTitle: 'LEGIT AT INSTANT',
    feedbackContent:
      '“I love Digipay because nakatulong ka na sa ibang consumers, nakaka-earn ka pa. Legit at instant pa!”',
    feedbackAuthor: '- Auna Marie',
  },
  {
    feedBackImageSource: Testi3,
    feedbackTitle: 'INDICATED SERVICE CHARGE',
    feedbackContent:
      '“I love Digipay because they let you write the service charge and post it sa receipt . Mas convincing sya sa mga client kasi indicated ‘yung service charge sa resibo. So clients trust us more.”',
    feedbackAuthor: '- Bonnalyn Tabuna Dasco',
  },

  {
    feedBackImageSource: Testi4,
    feedbackTitle: 'INDICATED SERVICE CHARGE',
    feedbackContent:
      '“I love digipay dahil sa laking tulong sa aking tanging pinagkukunan ng kabuhayan. Doble kita tuwing ginagamit ko ang napaka-simpleng app. Lagi kong ipinagmamalaki na gayahin ng ibang apps.”',
    feedbackAuthor: '- Ian Catulnam',
  },
  {
    feedBackImageSource: Testi5,
    feedbackTitle: 'EASY AND FRIENDLY TO USE',
    feedbackContent:
      '“I love digipay because it is a big help for me. Easy and friendly to use. 100% legit. Mag-Digipay na kayo sa barangay nyo.”',
    feedbackAuthor: '- Ghiefren F. Amor',
  },
  {
    feedBackImageSource: Testi6,
    feedbackTitle: 'NAKAKATULONG SA MGA KAPITBAHAY',
    feedbackContent:
      '“I love Digipay kasi dahil sa’yo nakakatulong ako sa neighborhood na maging easier din ang buhay tulad ng pagbabayad ng bills payment. Ang bilis ng top-up at madaling gamitin ang app.”',
    feedbackAuthor: '- Lilia San Gabriel Rocafor',
  },
  {
    feedBackImageSource: Testi7,
    feedbackTitle: 'RESPONSIBLE CUSTOMER SUPPORT',
    feedbackContent:
      '“I love digipay because you’re the best. Mabilis magtop-up. Kapag merong problem sa pagtransact ng bills payment ,mabilis din nila nasosolve ang problem. Easy lahat pati ang customer support nila responsible din.”',
    feedbackAuthor: '- Ycerp Garcia',
  },
  {
    feedBackImageSource: Testi8,
    feedbackTitle: 'BUSINESS FOR EVERYBODY',
    feedbackContent:
      '“I love Digipay because the app is easy to use. E-wallet, bills payment and e-loading, easy to add funds and issues are immediately corrected. As a whole it’s a good business for everybody.”',
    feedbackAuthor: '- Ros Viz',
  },
  {
    feedBackImageSource: Testi9,
    feedbackTitle: 'SMALL CAPITAL, BIG INCOME',
    feedbackContent:
      "“I love digipay because it's convenient mapa-personal or business use. Small capital but big income for a full time mom like me.”",
    feedbackAuthor: '- Clarenze Condino',
  },
  {
    feedBackImageSource: Testi10,
    feedbackTitle: 'ALL-IN-ONE APP',
    feedbackContent:
      '“I love digipay because it’s an all-in-one app. Simply tap the app and earn!”',
    feedbackAuthor: '- Donna Rosario',
  },
]

export default whyAgentsLoveDigipayData
